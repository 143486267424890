<template>
  <div>
    <div class="card my-4">
      <div class="card-body px-md-5 shadow-sm">
        <div class="row">
          <div class="col-md-6 py-2">
            <h5 class="mb-4 main-title">
              {{ $t("reservation.yourReservation") }}
            </h5>
            <table class="table custom-table">
              <tr>
                <td>{{ $t("reservation.totalNumberOfRooms") }}</td>
                <td class="text-right">{{ checkOutItem.quantity }}</td>
              </tr>
              <tr>
                <td>{{ $t("reservation.totalNumberOfPersons") }}</td>
                <td class="text-right">{{ checkOutItem.guests }}</td>
              </tr>
              <tr>
                <td>{{ $t("reservation.nights") }}</td>
                <td class="text-right">{{ nightsCount }}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-6 py-2">
            <h5 class="mb-4 main-title">{{ $t("reservation.travelPeriod") }}</h5>
            <table class="table custom-table">
              <tr>
                <td>{{ $t("reservation.checkIn") }}</td>
                <td class="text-right">
                  {{ formatData(checkoutData.date.checkIn) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("reservation.checkOut") }}</td>
                <td class="text-right">
                  {{ formatData(checkoutData.date.checkOut) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card my-4">
      <div class="card-body px-md-5 shadow-sm">
        <div class="d-md-block d-none">
          <table class="table">
            <tr>
              <th>{{ $t("reservation.noOfRooms") }}</th>
              <th>{{ $t("reservation.totalPrice") }}</th>
              <th class="text-center">{{ $t("property.personPerRoom") }}</th>
              <th>{{ $t("reservation.mealType") }}</th>
              <th>{{ $t("reservation.pricePerRoom") }}</th>
              <th>{{ $t("reservation.total") }}</th>
              <th>{{ $t("reservation.remove") }}</th>
            </tr>
            <tr
              v-for="(item, index) in checkoutData.cart"
              :key="`${item.id}-${index}`"
            >
              <td>{{ item.quantity }}x</td>
              <td class="text-main">
                <span>{{ productName(item.product) }}</span
                ><br />
                <div>
                  <span
                    role="button"
                    v-b-popover.bottom.hover="productName(item.product)"
                    >{{ $t("property.rateDetails") }}</span
                  >
                  | <span>{{ $t("property.cancellationPolicy") }}</span>
                </div>
              </td>
              <td class="text-center">
                <img
                  v-for="i in item.guests"
                  :key="i"
                  src="../../assets/icons/person.svg"
                  class="mr-1"
                  alt="person"
                />
              </td>
              <td>
                <template v-if="item.product?.meal?.breakfast">
                  <span>{{ $t("reservation.breakfast") }}</span>
                </template>
                <template v-else>
                  {{ $t("property.noMealIncluded") }}
                </template>
              </td>
              <td>
                <span class="text-main"
                  >{{ item.currencyCode }} {{ item.price }}</span
                >
              </td>
              <td>
                <span class="text-main"
                  ><b
                    >{{ item.currencyCode }} {{ item.price * item.quantity }}</b
                  ></span
                >
              </td>
              <td class="text-center">
                <img
                  @click="removeRoom()"
                  src="../../assets/icons/trash.svg"
                  class="mr-1 cursor-pointer"
                  alt="trash"
                />
              </td>
            </tr>
          </table>
        </div>
        <div class="d-md-none d-block">
          <table
            class="table"
            v-for="(item, index) in checkoutData.cart"
            :key="`${item.id}-${index}`"
          >
            <tr>
              <td>
                <span>{{ productName(item.product) }}</span
                ><br />
                <div>
                  <span
                    role="button"
                    v-b-popover.bottom.hover="productName(item.product)"
                    >{{ $t("property.rateDetails") }}</span
                  >
                  | <span>{{ $t("property.cancellationPolicy") }}</span>
                </div>
              </td>
              <td class="text-right">
                <img
                  @click="removeRoom()"
                  src="../../assets/icons/trash.svg"
                  class="mr-1 cursor-pointer"
                  alt="trash"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("reservation.noOfRooms") }}</td>
              <td class="text-right">{{ item.quantity }}x</td>
            </tr>
            <tr>
              <td>{{ $t("property.personPerRoom") }}</td>
              <td class="text-right">
                <img
                  v-for="i in item.guests"
                  :key="i"
                  src="../../assets/icons/person.svg"
                  class="mr-1"
                  alt="person"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("reservation.mealType") }}</td>
              <td class="text-right">
                <template v-if="item.product?.meal?.breakfast">
                  <span>{{ $t("reservation.breakfast") }}</span>
                </template>
                <template v-else>
                  {{ $t("property.noMealIncluded") }}
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t("reservation.pricePerRoom") }}</td>
              <td class="text-right">
                <span class="text-main"
                  >{{ item.currencyCode }} {{ item.price }}</span
                >
              </td>
            </tr>
            <tr>
              <td>{{ $t("reservation.total") }}</td>
              <td class="text-right">
                <span class="text-main"
                  ><b
                    >{{ item.currencyCode }} {{ item.price * item.quantity }}</b
                  ></span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <ValidationObserver ref="observer">
      <div class="card my-4" ref="bookerDetails">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4 main-title">{{ $t("reservation.yourDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="booker title"
                type="single-select"
                v-model="bookerDetails.namePrefix"
                :options="namePrefixList"
                :placeholder="$t('reservation.placeholderTitle')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="booker first name"
                v-model="bookerDetails.firstName"
                :placeholder="$t('reservation.placeholderFirstName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="booker last name"
                v-model="bookerDetails.lastName"
                :placeholder="$t('reservation.placeholderLastName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="booker email"
                type="email"
                v-model="bookerDetails.email"
                :placeholder="$t('reservation.placeholderEmail')"
                rules="required|email"
              />
            </div>
            <div class="col-md-5 my-3 no-border">
              <ValidatedInput
                name="booker phone"
                type="tel"
                :placeholder="$t('reservation.placeholderPhone')"
                v-model="bookerDetails.phoneNumber"
                rules="required"
                @setCountry="setCountryCode('bookerDetails', $event)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <h5 class="mb-4 main-title">{{ $t("reservation.guestDetails") }}</h5>
          <div class="row">
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest title"
                type="single-select"
                v-model="guestDetails.namePrefix"
                :options="namePrefixList"
                :placeholder="$t('reservation.placeholderTitle')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest first name"
                v-model="guestDetails.firstName"
                :placeholder="$t('reservation.placeholderFirstName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest last name"
                v-model="guestDetails.lastName"
                :placeholder="$t('reservation.placeholderLastName')"
                rules="required"
              />
            </div>
            <div class="col-md-5 my-3">
              <ValidatedInput
                name="guest email"
                type="email"
                v-model="guestDetails.email"
                :placeholder="$t('reservation.placeholderEmail')"
                rules="required|email"
              />
            </div>
            <div class="col-md-5 my-3 no-border">
              <ValidatedInput
                name="guest phone"
                type="tel"
                :placeholder="$t('reservation.placeholderPhone')"
                v-model="guestDetails.phoneNumber"
                rules="required"
                @setCountry="setCountryCode('guestDetails', $event)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <div class="d-flex justify-content-between">
            <h5 class="mb-4 main-title">
              {{ $t("reservation.companyDetails") }}
            </h5>
          </div>
          <div class="row">
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="company name"
                v-model="companyDetails.companyName"
                :placeholder="$t('reservation.placeholderCompanyName')"
                rules="required"
              />
            </div>
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="company address line"
                v-model="companyDetails.addressLine"
                :placeholder="$t('reservation.placeholderAddressLine')"
                rules="required"
              />
            </div>
            <div class="col-md-10 my-3">
              <ValidatedInput
                name="company street number"
                v-model="companyDetails.streetNumber"
                :placeholder="$t('reservation.placeholderStreetNo')"
                rules="required"
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="company city name"
                v-model="companyDetails.cityName"
                :placeholder="$t('reservation.placeholderCityName')"
                rules="required"
              />
            </div>
            <div class="col-md-3 my-3">
              <ValidatedInput
                name="company postal code"
                v-model="companyDetails.postalCode"
                :placeholder="$t('reservation.placeholderPostalCode')"
                rules="required"
              />
            </div>
            <div class="col-md-4 my-3">
              <ValidatedInput
                name="company country"
                type="country"
                v-model="companyDetails.countryName"
                placeholder="Country*"
                rules="required"
                @setCountryCode="companyDetails.countryCode = $event"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body px-md-5 shadow-sm">
          <div class="d-flex justify-content-between">
            <h5 class="mb-4 main-title">
              {{ $t("reservation.specialRequests") }}
            </h5>
            <div>
              <button
                @click="showSpecialRequest = !showSpecialRequest"
                class="btn btn-outline-main px-md-5 px-2"
              >
                {{ $t("listing.open") }}
                <img
                  src="../../assets/icons/open.svg"
                  class="mr-1"
                  alt="open"
                />
              </button>
            </div>
          </div>
          <div class="row" v-if="showSpecialRequest">
            <div
              class="col-md-3 my-3"
              v-for="request in ehotelSpecialRequests"
              :key="request"
            >
              <input
                name="terms and conditions"
                type="checkbox"
                class="mr-1"
                :value="request"
                :id="request"
                v-model="specialRequests"
              />
              <label :for="request" class="ml-2">{{ request }}</label>
            </div>
          </div>
        </div>
      </div>

      <CreditCardField
        @completeBooking="$emit('completeBooking', $event)"
        @formInvalid="$emit('formInvalid')"
        v-if="collectCardDetails"
      />

      <hr />

      <TermsAndPolicies />
    </ValidationObserver>
  </div>
</template>

<script>
import moment from "moment";
import CreditCardField from "./CreditCardField.vue";
import TermsAndPolicies from "./TermsAndPolicies.vue";
import { namePrefixList, ehotelSpecialRequests } from "../../shared";
export default {
  name: "EhotelCheckout",
  props: {
    checkoutData: {
      type: Object,
      required: true,
    },
    collectCardDetails: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CreditCardField,
    TermsAndPolicies,
  },
  data() {
    return {
      bookerDetails: {
        namePrefix: "",
        firstName: "",
        lastName: "",
        countryAccessCode: "",
        phoneNumber: "",
        phoneTechType: "1",
        email: "",
      },
      guestDetails: {
        namePrefix: "",
        firstName: "",
        lastName: "",
        countryAccessCode: "",
        phoneNumber: "",
        phoneTechType: "1",
        email: "",
      },
      companyDetails: {
        companyName: "",
        addressLine: "",
        streetNumber: "",
        cityName: "",
        postalCode: "",
        countryCode: "",
        countryName: "",
      },
      specialRequests: [],
      showSpecialRequest: false,
    };
  },
  computed: {
    namePrefixList: () => namePrefixList,
    ehotelSpecialRequests: () => ehotelSpecialRequests,
    checkOutItem() {
      return this.checkoutData.cart[0];
    },
    nightsCount() {
      const { checkIn, checkOut } = this.checkoutData.date;
      return moment(checkOut, "YYYY-MM-DD").diff(
        moment(checkIn, "YYYY-MM-DD"),
        "days"
      );
    },
  },
  methods: {
    productName(availability) {
      return availability?.sellableUnit?.roomType;
    },
    removeRoom() {
      const { id } = this.$route.params;
      sessionStorage.removeItem(`checkoutData-${id}`);
      this.$router.push(`/listing/${id}`);
    },
    setCountryCode(section, { dialCode }) {
      this[section].countryAccessCode = dialCode;
    },
    getData() {
      const { bookerDetails, guestDetails, companyDetails, specialRequests } =
        this;
      return {
        bookerDetails,
        guestDetails: [guestDetails],
        companyDetails,
        specialRequests,
      };
    },
    formatData(date) {
      return moment(date).format("ddd, DD MMM YYYY");
    },
  },
};
</script>

<style scoped>
table tr td {
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  h5 {
    font-size: 16px;
  }
  table tr td {
    font-size: 14px;
  }
}
</style>
