<template>
  <div class="card my-4">
    <div class="card-body px-md-5 shadow-sm">
      <h5 class="mb-4 main-title">{{ $t('reservation.creditCardDetails') }}</h5>
      <p>
        {{ $t('reservation.creditCardMessage') }}
      </p>
      <div class="row">
        <div class="col-md-8 my-3">
          <iframe
            width="100%"
            min-height="100vh"
            id="pci_iframe"
            :src="pciBookingUrl"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
        <div class="col-md-4 px-3">
          <img
            src="../../assets/images/pci-booking.svg"
            class="img-fluid"
            alt="PCI-Booking"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CreditCardField",
  data() {
    return {
      pciBookingUrl: "",
    };
  },
  async mounted() {
    const { data: response } = await this.getPaymentCardForm();
    this.pciBookingUrl = response?.data?.formDetails;
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessageFromPciBooking);
      window.addEventListener("cardTokenizationSuccess", async (event) => {
        this.$emit("completeBooking", event.detail);
      });
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", this.handleMessageFromPciBooking);
    }
  },
  methods: {
    ...mapActions("booking", ["getPaymentCardForm"]),
    handleMessageFromPciBooking(e) {
      if (e) {
        if (e.origin.toLowerCase() !== "https://service.pcibooking.net") {
          return;
        }
        if (e.data === "valid") {
          document
            .querySelector("#pci_iframe")
            .contentWindow.postMessage(
              "submit",
              "https://service.pcibooking.net"
            );
        } else if (e.data.startsWith("frameDimensionsChanged")) {
          const dims = e.data.split(":");
          const height = parseInt(dims[2]);
          document.querySelector("#pci_iframe").style.height = height + 5;
        } else if (e.data === "invalid") {
          this.$emit("formInvalid");
        }
      }
    },
  },
};
</script>

<style>
#pci_iframe {
  width: 100%;
  min-height: 300px;
  height: 100%;
}

@media screen and (max-width: 600px) {
  #pci_iframe {
    min-height: 400px;
  }
}
</style>
