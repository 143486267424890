<template>
  <div>
    <Spinner v-if="fetching" />
    <div v-else class="container-fluid plr-80 payment-page">
      <div class="mb-5">
        <BookingEngineDateSelector
          :promo-disabled="checkoutData.distributorType !== 'A'"
          :checkIn="formatDate(checkoutData.date.checkIn)"
          :checkOut="formatDate(checkoutData.date.checkOut)"
          :currencyCode="checkoutData.property?.homeCurrency?.code"
          :currencySymbol="checkoutData.property?.homeCurrency?.symbol"
          :distributorType="checkoutData.distributorType"
          @search="
            $router.push(
              `/listing/${$route.params.id}?checkIn=${$event.checkIn}&checkOut=${$event.checkOut}&findAvailability=true`
            )
          "
        />
      </div>

      <div class="card my-3">
        <div class="card-body px-md-5">
          <div
            class="tabs d-flex justify-content-between align-items-center py-3"
          >
            <div class="tab-section completed w-100">
              <div class="tab-icon"></div>
            </div>
            <div
              v-if="!noExtra"
              class="tab-section w-100"
              :class="{
                current: step === 'extra',
                completed: step === 'summary',
              }"
            >
              <p v-if="step === 'extra'" class="tab-label">
                {{ $t("reservation.extra") }}
              </p>
              <div class="tab-icon"></div>
            </div>
            <div
              class="tab-section w-100"
              :class="{ current: step === 'summary' }"
            >
              <p v-if="step === 'summary'" class="tab-label">
                {{ $t("reservation.summary") }}
              </p>
              <div class="tab-icon"></div>
            </div>
            <div class="tab-section">
              <div class="tab-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 'extra'">
        <div class="extra-list mt-5">
          <div v-for="extra in extras" :key="extra.id" class="card my-4 extra">
            <div class="card-body row px-md-5">
              <div class="col-md-4 my-2">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <template v-if="extra.media.length">
                      <div>
                        <img
                          :src="extraImages(extra)[0].url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </template>
                    <img
                      v-else
                      src="../assets/images/default-extra.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div>{{ extra.productDescription.name }}</div>
                </div>
              </div>
              <div class="col-md-2 col-6 my-auto">{{ extra.extraCode }}</div>
              <div
                class="col-md-2 col-6 my-auto d-md-block d-none"
                v-if="getExtraValue(extra.id)"
              >
                <div>{{ $t("reservation.quantity") }}</div>
                <Multiselect
                  placeholder=""
                  style="width: 70px"
                  class="mt-3"
                  :options="cultswitchQtyOption(extra)"
                  :showLabels="false"
                  :searchable="false"
                  :allow-empty="false"
                  @select="extraQuantityChanged($event, extra)"
                  :value="getExtraValue(extra.id)"
                >
                </Multiselect>
              </div>
              <div
                class="text-main font-weight-bold col-md-2 col-6 my-auto text-md-left text-right"
              >
                {{ extra.currencyCode }} {{ cultswitchProductPrice(extra) }}
              </div>
              <div
                class="col-6 d-md-none d-block"
                v-if="getExtraValue(extra.id)"
              >
                <Multiselect
                  placeholder=""
                  style="width: 70px"
                  class="mt-3"
                  :options="cultswitchQtyOption(extra)"
                  :showLabels="false"
                  :searchable="false"
                  :allow-empty="false"
                  @select="extraQuantityChanged($event, extra)"
                  :value="getExtraValue(extra.id)"
                >
                </Multiselect>
              </div>
              <div class="col-md-2 col-6 text-right my-auto ml-auto">
                <button
                  v-if="!getExtraValue(extra.id)"
                  @click="addExtra(extra)"
                  class="btn btn-main px-5 mt-md-0 mt-3"
                >
                  + {{ $t("reservation.add") }}
                </button>
                <button
                  v-else
                  @click="removeExtra(extra)"
                  class="btn btn-main px-5 mt-md-0 mt-3"
                >
                  {{ $t("reservation.remove") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 'summary'">
        <CultswitchCheckout
          v-if="checkoutData.distributorType === 'A'"
          :checkoutData="checkoutData"
          ref="cultswitchForm"
          @completeBooking="completeBooking"
          :collectCardDetails="collectCardDetails"
          @formInvalid="processingBooking = false"
        />
        <EhotelCheckout
          v-else-if="checkoutData.distributorType === 'B'"
          :checkoutData="checkoutData"
          :collectCardDetails="collectCardDetails"
          ref="ehotelForm"
          @completeBooking="completeBooking"
          @formInvalid="processingBooking = false"
        />
      </div>

      <BookingCheckout
        ref="bookingCheckout"
        class="my-md-5"
        :type="checkoutData.distributorType"
        :cart="checkoutData.cart"
        :extras="checkoutData.extras"
        :sticky="false"
        :buttonText="
          step === 'extra' ? $t('property.summary') : $t('property.bookNow')
        "
        @proceed="proceedWithBooking"
        :disabled="processingBooking"
        @clear="clearSelection"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Spinner from "./Widgets/Spinner.vue";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import BookingCheckout from "./Widgets/BookingCheckout.vue";
import EhotelCheckout from "./Reservation/EhotelCheckout.vue";
import CultswitchCheckout from "./Reservation/CultswitchCheckout.vue";
import BookingEngineDateSelector from "./Widgets/BookingEngineDateSelector.vue";
export default {
  name: "Reservation",
  components: {
    Spinner,
    Multiselect,
    EhotelCheckout,
    BookingCheckout,
    CultswitchCheckout,
    BookingEngineDateSelector,
  },
  data() {
    return {
      fetching: false,
      step: "extra",
      noExtra: false,
      checkoutData: null,
      extras: [],
      collectCardDetails: true,
      processingBooking: false,
    };
  },
  async created() {
    this.fetching = true;
    const { id } = this.$route.params;
    let checkoutData = sessionStorage.getItem(`checkoutData-${id}`);
    checkoutData = JSON.parse(checkoutData) || null;
    if (
      !checkoutData ||
      moment(checkoutData.date.checkIn).isBefore(
        moment(new Date()).format("YYYY-MM-DD")
      )
    ) {
      this.$router.push(`/listing/${id}`);
    }
    this.checkoutData = checkoutData;

    if (checkoutData.distributorType === "A") {
      const { data } = await this.findCultswitchAvailability({
        check_in: checkoutData.date.checkIn,
        check_out: checkoutData.date.checkOut,
        property_id: id,
        promo_code: checkoutData.promoCode,
      });
      this.extras = data?.data?.extras || [];
      if (!this.extras.length) {
        this.step = "summary";
        this.noExtra = true;
      }
      this.collectCardDetails = false;
      this.checkoutData.cart.forEach((item) => {
        const { bookingGuaranteeCode } = item.product;
        if (bookingGuaranteeCode && bookingGuaranteeCode.toString() === "3") {
          this.collectCardDetails = true;
        }
      });
    } else {
      this.noExtra = true;
      this.step = "summary";
    }

    this.fetching = false;
  },
  methods: {
    ...mapActions("booking", [
      "processCultswitchBooking",
      "processEhotelBooking",
    ]),
    ...mapActions("property", ["findCultswitchAvailability"]),
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    async proceedWithBooking() {
      if (this.step === "extra") {
        const { id } = this.$route.params;
        sessionStorage.setItem(
          `checkoutData-${id}`,
          JSON.stringify(this.checkoutData)
        );
        this.step = "summary";
      } else if (this.step === "summary") {
        let component;
        const { distributorType } = this.checkoutData;
        if (distributorType === "A") {
          component = this.$refs.cultswitchForm;
        } else if (distributorType === "B") {
          component = this.$refs.ehotelForm;
        }
        const observer = component.$refs.observer;
        const isValid = await observer.validate();
        if (isValid) {
          if (this.collectCardDetails) {
            this.processingBooking = true;
            document
              .querySelector("#pci_iframe")
              .contentWindow.postMessage(
                "validate",
                "https://service.pcibooking.net"
              );
          } else {
            this.processingBooking = true;
            await this.completeBooking();
          }
        } else if (!isValid) {
          const firstErrorField = Object.values(observer.refs).find(
            (el) => el.errors.length
          );

          if (firstErrorField) {
            firstErrorField.$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }
    },
    cultswitchProductPrice(product, guests = 1) {
      let prices = product.roomRates;
      if (prices[guests.toString()]) {
        return prices[guests.toString()].totalAmount;
      }
      prices = Object.values(prices);
      return prices.length ? prices[0].totalAmount : 0;
    },
    cultswitchQtyOption(extra) {
      return Array.from({ length: extra.numberOfUnits + 1 }, (_, i) => i);
    },
    addExtra(extra, quantity = 1) {
      if (quantity === 0) {
        this.removeExtra(extra);
        return false;
      }
      const idx = this.checkoutData.extras.findIndex(
        ({ id }) => extra.id === id
      );
      if (idx < 0) {
        this.checkoutData.extras.push({
          id: extra.id,
          quantity,
          price: this.cultswitchProductPrice(extra),
          extra,
        });
      } else {
        this.checkoutData.extras[idx]["quantity"] = quantity;
        this.checkoutData.extras[idx]["price"] = this.cultswitchProductPrice(
          extra,
          quantity
        );
      }
    },
    removeExtra(extra) {
      const idx = this.checkoutData.extras.findIndex(
        ({ id }) => extra.id === id
      );
      this.checkoutData.extras.splice(idx, 1);
    },
    getExtraValue(id) {
      const item = this.checkoutData.extras.find((extra) => extra.id === id);
      return item ? item.quantity : 0;
    },
    extraQuantityChanged(qty, extra) {
      this.addExtra(extra, qty);
    },
    extraImages(extra) {
      const images = {};
      extra.media.forEach((image) => {
        images[image.id] = image;
      });

      return Object.values(images).sort((a, b) => a.sortOrder - b.sortOrder);
    },
    async completeBooking(cardDetails = null) {
      let bookerEmail = "";
      let reservationId = "";
      try {
        if (this.checkoutData.distributorType === "A") {
          const bookingData = this.$refs.cultswitchForm.getData();
          const { data } = await this.processCultswitchBooking({
            ...this.checkoutData,
            ...bookingData,
            propertyId: this.$route.params.id,
            payment: cardDetails,
          });
          bookerEmail = bookingData.booker.email;
          reservationId = data.data.reservationId;
        } else if (this.checkoutData.distributorType === "B") {
          const bookingData = this.$refs.ehotelForm.getData();
          const { data } = await this.processEhotelBooking({
            ...this.checkoutData,
            ...bookingData,
            propertyId: this.$route.params.id,
            payment: cardDetails,
          });
          bookerEmail = bookingData.bookerDetails.email;
          reservationId = data.data.reservationId;
        }
        this.$toastr.s("Success", this.$t("reservation.reservationSuccess"));
        sessionStorage.removeItem(`checkoutData-${this.$route.params.id}`);
        this.$router.push({
          path: "/reservation_success",
          query: {
            type: this.checkoutData.distributorType,
            reservationId,
            total: this.$refs.bookingCheckout.totalAmount,
            currency: this.$refs.bookingCheckout.currencyCode,
            booker: bookerEmail,
            propertyId: this.$route.params.id,
          },
        });
      } catch (error) {
        this.$toastr.e("Error", this.$t("reservation.reservationError"));
      } finally {
        this.processingBooking = false;
      }
    },
    clearSelection() {
      const { id } = this.$route.params;
      sessionStorage.removeItem(`checkoutData-${id}`);
      this.$router.push(`/listing/${id}`);
    },
  },
};
</script>
